import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CustomParamsSerializer
} from "axios";
import {
  PureHttpError,
  RequestMethods,
  PureHttpResponse,
  PureHttpRequestConfig,
  defaultProjectConfig
} from "./types.d";
import { stringify } from "qs";
import NProgress from "../progress";
import { getToken } from "@/utils/auth";
import { message } from "@/utils/message";
const { VITE_GLOB_API_URL } = import.meta.env;
import { useUserStoreHook } from "@/store/modules/user";
import { encrypt } from "@EESA/components/src/librarys/jsencrypt";

// 存储每个请求的标识符和取消函数的 Map
const requestMap = new Map();

// 相关配置请参考：www.axios-js.com/zh-cn/docs/#axios-request-config-1
const defaultToken = window.btoa(
  defaultProjectConfig.clientId + ":" + defaultProjectConfig.clientSecret
);
const defaultConfig: AxiosRequestConfig = {
  // 请求超时时间
  timeout: 15000,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  },
  // 数组格式参数序列化（https://github.com/axios/axios/issues/5142）
  paramsSerializer: {
    serialize: stringify as unknown as CustomParamsSerializer
  }
};
// 白名单
const timeoutWhiteList = [
  "/eesa-home/builder/builderPosition/back/v1.0/export",
  "/exhibitor/exhibitors/back/v1.0/export"
];
class PureHttp {
  constructor() {
    this.httpInterceptorsRequest();
    this.httpInterceptorsResponse();
  }

  /** token过期后，暂存待执行的请求 */
  private static requests = [];

  /** 初始化配置对象 */
  private static initConfig: PureHttpRequestConfig = {};
  /** 保存当前Axios实例对象 */
  private static axiosInstance: AxiosInstance = Axios.create(defaultConfig);

  /** 请求拦截 */
  private httpInterceptorsRequest(): void {
    PureHttp.axiosInstance.interceptors.request.use(
      async (config: PureHttpRequestConfig) => {
        // 开启进度条动画
        NProgress.start();
        /*
          @author gang
          修改请求超时时间
        */
        if (timeoutWhiteList.some(url => config.url.includes(url))) {
          config.timeout = 60 * 60 * 1000;
        }

        /** 取消重复请求 **/
        // 生成唯一的请求标识符
        const requestId = `${config.method}-${config.url}`;
        // 如果之前存在相同请求，则取消之前的请求
        if (requestMap.has(requestId)) {
          const source = requestMap.get(requestId);
          source.cancel(`Request canceled: ${requestId}`);
        }
        // 创建 CancelToken.source 并将其传递给请求配置
        const source = Axios.CancelToken.source();
        config.cancelToken = source.token;

        // 存储请求标识符和对应的 CancelToken
        requestMap.set(requestId, source);

        // 优先判断post/get等方法是否传入回掉，否则执行初始化设置等回掉
        if (typeof config.beforeRequestCallback === "function") {
          config.beforeRequestCallback(config);
          return config;
        }
        if (PureHttp.initConfig.beforeRequestCallback) {
          PureHttp.initConfig.beforeRequestCallback(config);
          return config;
        }
        /** 请求白名单，放置一些不需要token的接口（通过设置请求白名单，防止token过期后再请求造成的死循环问题） */
        const whiteList = [`${VITE_GLOB_API_URL}/api-uaa/oauth/token`];

        // 添加平台标识
        config.headers["tenant"] = defaultProjectConfig.clientId;
        // 定义请求链接
        config.url = `${VITE_GLOB_API_URL}${config.url}`;
        // 添加默认token
        const token = getToken();

        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        } else {
          config.headers["Authorization"] = "Basic " + defaultToken;
        }
        config.headers["sign"] = encrypt(config);
        return whiteList.indexOf(config.url) === -1
          ? config
          : new Promise(resolve => {
              config.headers["Authorization"] = "Basic " + defaultToken;
              resolve(config);
            });
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  /** 响应拦截 */
  private httpInterceptorsResponse(): void {
    const instance = PureHttp.axiosInstance;
    instance.interceptors.response.use(
      async (response: PureHttpResponse) => {
        // 移除已完成的请求
        const requestId = `${response.config.method}-${response.config.url}`;
        if (requestMap.has(requestId)) {
          requestMap.delete(requestId);
        }
        if (response.config.params && response.config.params.specialBlob) {
          return response;
        }
        const $config = response.config;
        const data = response.data;
        const code = data.resp_code ?? data.code;
        // 关闭进度条动画
        NProgress.done();
        // 优先判断post/get等方法是否传入回掉，否则执行初始化设置等回掉
        if (typeof $config.beforeResponseCallback === "function") {
          $config.beforeResponseCallback(response);
          return response.data;
        }
        if (PureHttp.initConfig.beforeResponseCallback) {
          PureHttp.initConfig.beforeResponseCallback(response);
          return response.data;
        }
        if (code === undefined) {
          return response.data;
        }
        if (code === 1001 || code === 1002 || code === 3008) {
          await useUserStoreHook().logOut();
          return response.data;
        }
        if (code !== 0) {
          message(data.resp_msg || data.message, { type: "error" });
        }
        return response.data;
      },
      (error: PureHttpError) => {
        try {
          if (error.response.data.resp_code !== 0) {
            message(
              error.response.data.resp_msg || error.response.data.message,
              { type: "error" }
            );
          }
        } catch (e) {
          const $error = error;
          $error.isCancelRequest = Axios.isCancel($error);
          // 关闭进度条动画
          NProgress.done();
          // 所有的响应异常 区分来源为取消请求/非取消请求
          return Promise.reject($error.response);
        }
        const $error = error;
        $error.isCancelRequest = Axios.isCancel($error);
        NProgress.done();
        try {
          if (error.response.data.resp_code !== 0) {
            message(
              error.response.data.resp_msg || error.response.data.message,
              { type: "error" }
            );
          }
        } catch (e) {
          return Promise.reject($error.response);
        }
        return Promise.reject($error.response);
      }
    );
  }

  /** 通用请求工具函数 */
  public request<T>(
    method: RequestMethods,
    url: string,
    param?: AxiosRequestConfig,
    axiosConfig?: PureHttpRequestConfig
  ): Promise<T> {
    const config = {
      method,
      url,
      ...param,
      ...axiosConfig
    } as PureHttpRequestConfig;

    // 单独处理自定义请求/响应回掉
    return new Promise((resolve, reject) => {
      PureHttp.axiosInstance
        .request(config)
        .then((response: undefined) => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /** 单独抽离的post工具函数 */
  public post<T, P>(
    url: string,
    params?: AxiosRequestConfig<T>,
    config?: PureHttpRequestConfig
  ): Promise<P> {
    return this.request<P>("post", url, params, config);
  }

  /** 单独抽离的get工具函数 */
  public get<T, P>(
    url: string,
    params?: AxiosRequestConfig<T>,
    config?: PureHttpRequestConfig
  ): Promise<P> {
    return this.request<P>("get", url, params, config);
  }
}

export const http = new PureHttp();
