import {
  UserResult,
  RefreshTokenResult,
  getUserInfo,
  buttonLimit,
  loginOutApi,
  configListBefore
} from "@/api/user";
import { get } from "lodash";
import { store } from "@/store";
import { userType } from "./types";
import { defineStore } from "pinia";
import { regIP } from "@/utils/rule";
import { getToken } from "@/utils/auth";
import { message } from "@/utils/message";
import { routerArrays } from "@/layout/types";
import { router, resetRouter } from "@/router";
import { storageSession } from "@pureadmin/utils";
import { useMultiTagsStoreHook } from "@/store/modules/multiTags";
import { getLogin, refreshTokenApi, getPublicKeyApi } from "@/api/user";
import { type DataInfo, setToken, removeToken, sessionKey } from "@/utils/auth";
const { VITE_APP_DOMAIN, VITE_API_FILE_CONFIG } = import.meta.env;
export const useUserStore = defineStore({
  id: "pure-user",
  state: (): userType => ({
    // 用户名
    username:
      storageSession().getItem<DataInfo<number>>(sessionKey)?.username ?? "",
    // 页面级别权限
    roles: storageSession().getItem<DataInfo<number>>(sessionKey)?.roles ?? [],
    publicKey: "",
    fileUrl: "",
    watermarkShow: false,
    buttonPermissionsList: [],
    paging: null,
    userInfo: {}
  }),
  actions: {
    /** 存储用户名 */
    SET_USERNAME(username: string) {
      this.username = username;
    },
    /** 存储角色 */
    SET_ROLES(roles: Array<string>) {
      this.roles = roles;
    },
    // 获取加密密钥
    async getPublicKey() {
      return new Promise((resolve, reject) => {
        getPublicKeyApi()
          .then(data => {
            if (data["resp_code"] === 0) {
              this.publicKey = data["datas"];
              window.localStorage.setItem("publicKey", data["datas"]);
              resolve(data);
            } else {
              reject(data);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 设置加密密钥
    setPublicKey() {
      this.publicKey = window.localStorage.getItem("publicKey");
    },
    /** 登入 */
    async loginByUsername(data) {
      return new Promise<UserResult>((resolve, reject) => {
        getLogin(data)
          .then(async data => {
            if (data["resp_code"] === 0) {
              data["datas"] = Object.assign(data["datas"], {
                domain: regIP.test(window.location.hostname)
                  ? window.location.hostname
                  : VITE_APP_DOMAIN
              });
              setToken(data["datas"]);
              resolve(data);
              await this.handleGetUserInfo();
              await this.getConfigUrl();
            } else {
              reject(data);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /** 前端登出（不调用接口） */
    logOut(path?: string) {
      loginOutApi(getToken()).then(res => {
        const code = get(res, "resp_code", 1001);
        if (code === 0) {
          this.username = "";
          this.roles = [];
          this.userInfo = {};
          this.watermarkShow = false;
          removeToken({
            domain: regIP.test(window.location.hostname)
              ? window.location.hostname
              : VITE_APP_DOMAIN
          });
          useMultiTagsStoreHook().handleTags("equal", [...routerArrays]);
          resetRouter();
          router.push(path ? path : "/login");
        }
      });
    },
    restProcessing(path?: string) {
      this.username = "";
      this.roles = [];
      useMultiTagsStoreHook().handleTags("equal", [...routerArrays]);
      resetRouter();
      router.push(path ? path : "/login");
    },
    /** 获取用户信息 */
    async handleGetUserInfo() {
      return new Promise<void>((resolve, reject) => {
        getUserInfo()
          .then(response => {
            const data = response.datas;
            this.userInfo = data;
            this.watermarkShow = true;
            if (data.roles.length === 0 || data.roles === undefined) {
              window.localStorage.setItem("roles", JSON.stringify(false));
              message("暂无权限，请联系管理员", { type: "error" });
              reject("暂无权限，请联系管理员");
            } else {
              window.localStorage.setItem("roles", JSON.stringify(true));
            }
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /** 刷新`token` */
    async handRefreshToken(data) {
      return new Promise<RefreshTokenResult>((resolve, reject) => {
        refreshTokenApi(data)
          .then(data => {
            if (data) {
              data["datas"] = Object.assign(data["datas"], {
                domain: regIP.test(window.location.hostname)
                  ? window.location.hostname
                  : VITE_APP_DOMAIN
              });
              setToken(data["datas"]);
              resolve(data);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //* 获取项目配置 */
    async getConfigUrl() {
      try {
        const { datas } = await configListBefore(VITE_API_FILE_CONFIG);
        this.getButtonLimit();
        this.fileUrl = datas.find(item => item.type === "OSS").url;
      } catch (err) {
        return err;
      }
    },
    //* 获取按钮权限 */
    async getButtonLimit() {
      return new Promise<void>((resolve, reject) => {
        buttonLimit()
          .then(res => {
            const { datas, resp_code } = res;
            if (resp_code === 0) {
              this.buttonPermissionsList = datas;
              resolve(datas);
            } else {
              reject(res);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //* 设置分页数据 */
    setPaging(data) {
      if (data) {
        this.paging = JSON.parse(data);
      } else {
        this.paging = null;
      }
    },
    buttonIsLimit(buttonInfo: any) {
      const index = this.buttonPermissionsList.findIndex(item => {
        return item.name === buttonInfo;
      });
      if (index === -1) return false;
      return this.buttonPermissionsList[index].availableNum === -1;
    }
  }
});

export function useUserStoreHook() {
  return useUserStore(store);
}
