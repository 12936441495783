export default {
  path: "/exhibitorAccountNumber",
  redirect: "/exhibitorInfo",
  name: "ExhibitorAccountNumber",
  meta: {
    icon: "accountNumber",
    title: "展商账号",
    showLink: true,
    rank: 3
  },
  children: [
    {
      path: "/exhibitorInfo",
      name: "ExhibitorInfo",
      component: () =>
        import("@/views/exhibitorInfo/exhibitorAccountNumber.vue"),
      meta: {
        cache: true,
        title: "展商信息"
      },
      children: [
        {
          path: "/exhibitorInfo/Details",
          name: "ExhibitorAccountNumberDetails",
          component: () => import("@/views/exhibitorInfo/Details/index.vue"),
          meta: {
            title: "展商详情",
            showLink: false
          }
        }
      ]
    },
    {
      path: "/SALES",
      name: "SALES",
      component: () => import("@/views/exhibitorInfo/SALES.vue"),
      meta: {
        title: "销售信息"
      }
    },
    {
      path: "/votingSummary",
      name: "VotingSummary",
      component: () => import("@/views/exhibitorInfo/votingSummary.vue"),
      meta: {
        title: "投票汇总"
      }
    },
    {
      path: "/informationReview",
      name: "InformationReview",
      component: () => import("@/views/exhibitorInfo/informationReview.vue"),
      meta: {
        cache: true,
        title: "信息审核"
      },
      children: [
        {
          path: "/awardApplication/Details",
          name: "AwardApplicationDetails",
          component: () =>
            import(
              "@/views/exhibitorInfo/component/toExamine/details/exhibitionInformationDetails.vue"
            ),
          meta: {
            title: "展品审核详情",
            showLink: false
          }
        },
        {
          path: "/sponsorshipMaterials/Details",
          name: "SponsorshipMaterials",
          component: () =>
            import(
              "@/views/exhibitorInfo/component/toExamine/details/sponsorshipMaterialsDetails.vue"
            ),
          meta: {
            title: "赞数物料审核详情",
            showLink: false
          }
        },
        {
          path: "/exhibitionInformation/Details",
          name: "ExhibitionInformationDetails",
          component: () =>
            import(
              "@/views/exhibitorInfo/component/toExamine/details/awardApplicationDetails.vue"
            ),
          meta: {
            title: "奖项申报详情",
            showLink: false
          }
        },
        {
          path: "/exhibitorSponsorInfo/Details",
          name: "ExhibitorSponsorInfo",
          component: () =>
            import(
              "@/views/exhibitorInfo/component/toExamine/details/ExhibitorSponsorInformationDetails.vue"
            ),
          meta: {
            title: "展商/赞助商信息详情",
            showLink: false
          }
        }
      ]
    }
  ]
} as RouteConfigsTable;
