export default {
  path: "/registrationInfo",
  redirect: "/boothBooking",
  name: "RegistrationInfo",
  meta: {
    icon: "memo",
    title: "登记信息汇总",
    showLink: true,
    rank: 1
  },
  children: [
    {
      path: "/boothBooking",
      name: "BoothBooking",
      component: () => import("@/views/registrationInfo/boothBooking.vue"),
      meta: {
        cache: true,
        title: "展位预定"
      },
      children: [
        {
          path: "/boothBooking/details",
          name: "BoothBookingDetails",
          component: () => import("@/views/registrationInfo/Details/index.vue"),
          meta: {
            title: "详情",
            showLink: false
          }
        }
      ]
    },
    {
      path: "/forumParticipation",
      name: "ForumParticipation",
      component: () =>
        import("@/views/registrationInfo/forumParticipation.vue"),
      meta: {
        cache: true,
        title: "论坛参会"
      },
      children: [
        {
          path: "/forumParticipation/details",
          name: "ForumParticipationDetails",
          component: () => import("@/views/registrationInfo/Details/index.vue"),
          meta: {
            title: "详情",
            showLink: false
          }
        }
      ]
    },
    {
      path: "/mediaCooperation",
      name: "MediaCooperation",
      component: () => import("@/views/registrationInfo/mediaCooperation.vue"),
      meta: {
        cache: true,
        title: "媒体合作"
      },
      children: [
        {
          path: "/mediaCooperation/details",
          name: "MediaCooperationDetails",
          component: () => import("@/views/registrationInfo/Details/index.vue"),
          meta: {
            title: "详情",
            showLink: false
          }
        }
      ]
    },
    {
      path: "/audienceReservation",
      name: "AudienceReservation",
      component: () =>
        import("@/views/registrationInfo/audienceReservation.vue"),
      meta: {
        cache: true,
        title: "观众预约"
      },
      children: [
        {
          path: "/audienceReservation/details",
          name: "AudienceReservationDetails",
          component: () => import("@/views/registrationInfo/Details/index.vue"),
          meta: {
            title: "详情",
            showLink: false
          }
        }
      ]
    }
  ]
} as RouteConfigsTable;
